.filter {
    background-color: @color-ui-grey-light;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
}

.no-js .filter-js {
    display: none;
}

.filter-control {
    margin: 0 1rem 1rem;

    &:last-child {
        margin-bottom: 0;
    }

    .on-handhelds({
        width: 100%;
    });
}

.filter-label {
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 600;
    display: block;
    margin-bottom: 0.3em;
    color: @color-text-light;
}

.filter-choice,
.filter-search {
    background-color: white;
    border: 2px solid @color-ui-grey-normal;
    border-radius: 2px;
    padding: 0.625rem 1rem;
    width: 100%;
    transition: border .2s;

    &:focus {
        border-color: @color-accent;
        outline: none;
    }
}

.filter-tag {
    margin: 0;
    padding: 0;

    > li {
        display: inline-block;
        vertical-align: top;
    }

    button {
        border-radius: 2px;
        padding: 0.3em 0.6em;
        font-size: 0.9rem;
        background-color: white;
        cursor: pointer;
        line-height: 1;
        transition: background-color .2s, border-color .2s, color .2s;
        border: 2px solid white;
        margin: 0 0 .3em;

        &:focus {
            outline: none;
        }

        &:focus,
        &:active,
        &:hover {
            background-color: @color-ui-grey-normal;
        }

        &.pressed {
            background-color: white;
            border-color: @color-accent;
            color: @color-accent;
        }

        img {
            height: .7em;
        }
    }
}

.filterable {
    transition: max-height .2s, opacity .2s, margin .2s, padding .2s;
    overflow: hidden;
}

.filter-hide {
    opacity: 0 !important;
    margin: 0 !important;
    max-height: 0 !important;
    border-width: 0 !important;
    padding: 0 !important;
}
