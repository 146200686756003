.alert {
    padding: 1em 1.5em;
    border-color: rgba(0, 0, 0, 0.2);
    margin: 0.5em 0;
    border-radius: 2px;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.alert-success {
    background-color: @color-success;
    color: white;
}

.alert-error {
    background-color: @color-error;
    color: white;
}
