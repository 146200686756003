@import "../definitions/mixins";

.navbar {

}

.nav {
    margin: 0;
    padding: 0;

    > li {
        display: inline-block;
    }
}

.nav-root-main {
    margin: 1rem 0 0;
    background-color: #f2f2f2;
    transform: translateZ(0);

    .nav {
        max-width: 80%;
        margin: 0 auto;
    }

    .on-handhelds({
        position: fixed;
        left: 0;
        top: @page-header-size-mobile;
        background-color: @color-accent;
        height: calc(100vh - @page-header-size-mobile);
        width: 65vw;
        margin: 0;
        transform: translateX(-110%);
        opacity: 0;
        transition: transform .2s ease-out, opacity .2s ease-out;
        z-index: 1000;

        .nav-horizontal {
            flex-direction: column;
            max-width: none;
            padding: 1rem 0;

            > li {
                margin-bottom: 0.5rem;
            }
        }

        .nav-item {
            color: white;
            text-align: left;
            border-left: 5px solid transparent;
            -webkit-tap-highlight-color: fadeout(darken(@color-accent, 10%), 30%);
        }

        .nav-item-current {
            border-color: white;
        }

        .nav-state:checked ~ .page & {
            transform: translateX(0);
            opacity: 1;
        }
    });
}

.nav-horizontal {
    display: flex;

    > li {
        display: block;
        flex: 1;
    }

    .nav-item {
        display: block;
    }
}

.nav-item {
    color: @color-accent;
    font-weight: 600;
    text-align: center;
    padding: 1em 1.2em;
    text-decoration: none;
    position: relative;
    transition: color .1s;
    overflow: hidden;

    span {
        position: relative;
        z-index: 10;
    }

    &:before {
        content: " ";
        position: absolute;
        display: block;
        top: 0; bottom: 0; left: 0; right: 0;
        transform: scaleY(0);
        transform-origin: 50% 100%;
        transition: transform .2s ease-out;
        background-color: @color-accent;
    }

    &:hover,
    &:focus,
    &:active {
        color: white;

        &:before {
            transform: scaleY(1);
        }
    }
}

.nav-item-current {
    &:before {
        transform: scaleY(0.05);
    }
}

.nav-bars {
    display: flex;
    width: 3.75rem;
    height: 3.75rem;
    background-color: #f2f2f2;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &:after {
        content: "|||";
        transform: rotate(90deg) translate(-1px, -1px);
        display: inline-block;
        letter-spacing: -0.15em;
        font-weight: bold;
        line-height: 1;
    }
}

.nav-trigger {
    position: absolute;
    visibility: hidden;

    .on-handhelds({
        position: static;
        visibility: visible;
    });
}

.nav-state {
    display: none;
}
