.content {
    margin: 0 auto;
}

.content-narrow {
    width: 582px;
    max-width: 100%;
}

.content-title {
    color: @color-brand;
    margin-top: 0;
    border-bottom: 2px solid @color-brand;
    padding-bottom: .2em;
    font-size: 2.25rem;
}

.content-number-titles {
    counter-reset: h2;

    h1, h2, h3, h4, h5, h6 {
        &[id]:hover a,
        &[id]:focus a {
            opacity: 1;
            pointer-events: all;
        }
    }

    h2 {
        counter-reset: h3
    }
    h3 {
        counter-reset: h4
    }
    h4 {
        counter-reset: h5
    }
    h5 {
        counter-reset: h6
    }

    h2:before {
        counter-increment: h2;
        content: counter(h2) ". "
    }
    h3:before {
        counter-increment: h3;
        content: counter(h2) "." counter(h3) ". "
    }
    h4:before {
        counter-increment: h4;
        content: counter(h2) "." counter(h3) "." counter(h4) ". "
    }
    h5:before {
        counter-increment: h5;
        content: counter(h2) "." counter(h3) "." counter(h4) "." counter(h5) ". "
    }
    h6:before {
        counter-increment: h6;
        content: counter(h2) "." counter(h3) "." counter(h4) "." counter(h5) "." counter(h6) ". "
    }
}

.note {
    margin-bottom: 1rem;
    display: block;

    > br:first-child {
        display: none;
    }
}
