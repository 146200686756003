.blurb {
    margin: 1rem 0 0;

    .on-handhelds({
        margin-top: 0;
    });
}

.blurb-image {
    display: block;
    width: 100%;
}
