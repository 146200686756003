.btn {
    display: inline-block;
    white-space: nowrap;
    padding: 0.825em 2.475em;
    font-size: 1rem;
    border: 2px solid transparent;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    transition: border-color .2s, background-color .2s, color .2s;
    overflow: hidden;
    position: relative;
    z-index: 10;
    line-height: 1;
    background-color: transparent;

    span {
        position: relative;
        z-index: 10;
    }

    &:before {
        display: block;
        content: " ";
        position: absolute;
        top: 0; bottom: 0; left: 0; right: 0;
        transform: scaleY(0);
        transform-origin: 50% 100%;
        transition: transform .2s;
        z-index: 5;
        backface-visibility: hidden;
    }

    &:hover,
    &:focus,
    &:active {
        outline: none;

        &:before {
            transform: scaleY(1);
        }
    }
}

.btn-default {
    background-color: white;
    border-color: @color-accent;
    color: @color-accent;

    &:before {
        background-color: @color-accent;
    }

    &:hover,
    &:focus,
    &:active {
        color: white;
    }
}

.btn-primary {
    background-color: @color-accent;
    border-color: @color-accent;
    color: white;

    &:before {
        background-color: darken(@color-accent, 10%);
    }
}

