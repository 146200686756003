.form {

}

.form-group,
.form-control {
    font-size: 1.25rem;
    background-color: white;
    border-radius: 2px;
    transition: border-color .2s;
    border: 2px solid @color-ui-grey-light;
}

.form-group {
    margin-bottom: 1rem;
    position: relative;
    padding: 0;

    .no-js & {
        border: none;
        padding: 0;
    }

    .focus () {
        border-color: @color-accent;
    }

    &.input-focused {
        .focus()
    }

    &:focus-within {
        .focus()
    }

    &.has-error {
        margin-bottom: 3rem;
        border-color: @color-error;
        border-radius: 2px 2px 0 2px;
    }
}

.help-block {
    position: absolute;
    font-size: 0.9rem;
    z-index: 5;
    background-color: @color-error;
    color: white;
    top: 100%;
    padding: .3em .5em;
    right: 0;
    margin: 0;
    border-radius: 0 0 2px 2px;

    .js & {
        margin: 0 -2px 0 0;
    }

    strong {
        font-weight: normal;
    }
}

.form-control {
    padding: 1.09375rem 1rem;
    display: block;
    width: 100%;

    .no-js .has-error & {
        border-color: @color-error;
        border-radius: 2px 2px 0 2px;
    }

    .js & {
        padding: 1.5625rem 1rem .625rem;
        border: none;
    }

    &:focus {
        outline: none;
        border-color: @color-accent;
    }
}

.control-label {
    color: @color-text-light;
    transition: top .2s, transform .2s, font-size .2s, color .2s;
    display: block;
    transform-origin: 0 50%;
    padding: 0 1rem;
    white-space: nowrap;

    .no-js & {
        margin-bottom: .3125rem;
    }

    .js & {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .js .input-empty.input-type-textarea & {
        top: .825rem;
        transform: none;
    }

    .focus () {
        top: .225rem !important;
        transform: scale(.675) translateX(calc(1rem - (1rem * .675) + 2px)) !important;
        color: @color-accent;
        background-color: white;
    }

    .js .input-filled &,
    .js .input-focused & {
        background-color: white;
        .focus()
    }

    .form-group:focus-within & {
        .focus()
    }
}
