.foods {
    margin: 0;
    padding: 0;

    > li {
        &:extend(.filterable);
        display: block;
        margin-bottom: .75rem;
        max-height: 50px;
    }

    input {
        display: none;
    }
}

.food {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: start;
    user-select: none;

    label& {
        position: relative;

        input:checked + &::after {
            opacity: 1;
        }

        &::before,
        &::after {
            content: " ";
            min-width: 1.5rem;
            min-height: 1.5rem;
            border: 2px solid;
            border-radius: 2px;
        }

        &::before {
            display: inline-block;
            margin-right: 0.75rem;
            border-color: #ccc;
        }

        &::after {
            background-image: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjNkMyMjdGIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgeD0iMHB4IiB5PSIwcHgiPjx0aXRsZT5fPC90aXRsZT48Zz48cGF0aCBkPSJNMTAsMThhMS4wMDAxMiwxLjAwMDEyLDAsMCwxLS43MDctLjI5M2wtNC00QS45OTk4OS45OTk4OSwwLDAsMSw2LjcwNywxMi4yOTNsMy4yNTQ4OCwzLjI1NDg4LDguMjk0OTMtOS4yMTY4QS45OTk4OS45OTk4OSwwLDAsMSwxOS43NDMxNiw3LjY2OWwtOSwxMEExLjAwMjE4LDEuMDAyMTgsMCwwLDEsMTAuMDI2MzcsMThaIj48L3BhdGg+PC9nPjwvc3ZnPg==);
            background-size: cover;
            position: absolute;
            left: 0;
            top: 0;
            transition: opacity .2s;
            opacity: 0;
            border-color: transparent;
        }
    }
}

.food-type {
    &:extend(.filterable);
    max-height: 200px;
    font-size: 1.4rem;
    margin: 0 0 .5em 0;
    padding: 0 0 .2em 0;
    border-bottom: 2px solid #f2f2f2;

    .food-type-base {
        display: block;

        small {
            color: #666;
            font-size: .75em;
        }
    }

    > small {
        display: block;
        font-size: .95rem;
        color: @color-text-light;
    }
}

.food-props {
    vertical-align: text-top;
    font-size: 1rem;

    img {
        height: 1em;
    }

    &:empty {
        display: none;
    }
}

.food-selection {
    &:extend(.filterable);
    page-break-inside: avoid;
    padding-bottom: 3rem;
}

.food-selections {
    column-count: 2;
    column-gap: 1rem;
    margin: 2rem 0 -3rem;

    .on-handhelds({
        column-count: 1;
    });
}

.food-order {
    .page-actions {
        border-top: none;
        margin-top: 3rem;
    }
}

.food-order-submit {
    text-align: right;

    .on-small-devices({
        text-align: center;
    });
}
