.gallery {
    margin: 0 -.5rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > li {
        display: inline-block;
        margin: 0 .5rem 1rem;
    }
}

.gallery-image {
    max-height: 150px;
    overflow: hidden;
    border-radius: 2px;
    display: block;
    position: relative;
    z-index: 1;

    img {
        max-height: 150px;
        max-width: 100%;
    }

    &:after,
    &:before {
        position: absolute;
        opacity: 0;
    }

    &:before {
        content: " ";
        display: block;
    }
}
