.tooltip-element, .tooltip-element:after, .tooltip-element:before, .tooltip-element *, .tooltip-element *:after, .tooltip-element *:before {
    box-sizing: border-box;
}

.tooltip-element {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s;
}

.tooltip-element.tooltip-open {
    visibility: visible;
    opacity: 1;
}

.tooltip-element.tooltip-theme-arrows {
    max-width: 100%;
    max-height: 100%;
}

.tooltip-element.tooltip-theme-arrows .tooltip-content {
    border-radius: 2px;
    position: relative;
    font-family: inherit;
    background: #f2f2f2;
    color: @color-text-light;
    padding: 1em;
    font-size: .9rem;
    font-style: italic;
    max-width: 284px;
}

.tooltip-element.tooltip-theme-arrows .tooltip-content:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-width: 6px;
    border-style: solid;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-center .tooltip-content {
    margin-bottom: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-center .tooltip-content:before {
    top: 100%;
    left: 50%;
    margin-left: -6px;
    border-top-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-center .tooltip-content {
    margin-top: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-center .tooltip-content:before {
    bottom: 100%;
    left: 50%;
    margin-left: -6px;
    border-bottom-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-right.tooltip-element-attached-middle .tooltip-content {
    margin-right: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-right.tooltip-element-attached-middle .tooltip-content:before {
    left: 100%;
    top: 50%;
    margin-top: -6px;
    border-left-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-left.tooltip-element-attached-middle .tooltip-content {
    margin-left: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-left.tooltip-element-attached-middle .tooltip-content:before {
    right: 100%;
    top: 50%;
    margin-top: -6px;
    border-right-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-bottom .tooltip-content {
    margin-top: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-bottom .tooltip-content:before {
    bottom: 100%;
    left: 6px;
    border-bottom-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-bottom .tooltip-content {
    margin-top: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-bottom .tooltip-content:before {
    bottom: 100%;
    right: 6px;
    border-bottom-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-top .tooltip-content {
    margin-bottom: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-top .tooltip-content:before {
    top: 100%;
    left: 6px;
    border-top-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-top .tooltip-content {
    margin-bottom: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-top .tooltip-content:before {
    top: 100%;
    right: 6px;
    border-top-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content {
    margin-right: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content:before {
    top: 6px;
    left: 100%;
    border-left-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content {
    margin-left: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-top.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content:before {
    top: 6px;
    right: 100%;
    border-right-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content {
    margin-right: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-right.tooltip-target-attached-left .tooltip-content:before {
    bottom: 6px;
    left: 100%;
    border-left-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content {
    margin-left: 8px;
}

.tooltip-element.tooltip-theme-arrows.tooltip-element-attached-bottom.tooltip-element-attached-left.tooltip-target-attached-right .tooltip-content:before {
    bottom: 6px;
    right: 100%;
    border-right-color: #f2f2f2;
}

.tooltip-element.tooltip-theme-arrows {
    pointer-events: none;
}

.tooltip-element.tooltip-theme-arrows .tooltip-content {
    padding: 0.5em 1em;
}
