.on-handhelds(@rules) {
    @media (max-width: @screen-xs-max) {
        @rules();
    }
}

.on-tablets(@rules) {
    @media (min-width: @screen-sm-min) and (max-width: @screen-md-max) {
        @rules();
    }
}

.on-small-devices(@rules) {
    @media (max-width: @screen-sm-max) {
        @rules();
    }
}

.on-small-tablets(@rules) {
    @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
        @rules();
    }
}

.on-large-tables(@rules) {
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
        @rules();
    }
}

.on-desktops(@rules) {
    @media (min-width: @screen-lg-min) {
        @rules();
    }
}

.on-mobiles(@rules) {
    @media (max-width: @screen-md-min) {
        @rules();
    }
}

.material-shadow(@level) {
    & when(@level = 1) {
        box-shadow+: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)
    }

    & when(@level = 2) {
        box-shadow+: 0 8px 17px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    }

    & when(@level = 3) {
        box-shadow+: 0 12px 15px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
    }

    & when(@level = 4) {
        box-shadow+: 0 16px 28px 0 rgba(0,0,0,0.22),0 25px 55px 0 rgba(0,0,0,0.21);
    }

    & when(@level = 5) {
        box-shadow+: 0 27px 24px 0 rgba(0,0,0,0.2),0 40px 77px 0 rgba(0,0,0,0.22);
    }
}
