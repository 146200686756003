@import "../definitions/mixins";

.page {
    width: 792px;
    max-width: 100%;
    margin: 0 auto 50px;

    .on-handhelds({
        margin-bottom: 0;
    });
}

.page-inner {
    background-color: white;
}

.page-header {
    text-align: center;
    height: 220px;
    padding: 3.25rem 3rem;
    border-top: 10px solid @color-brand;
    background: url("../../img/floral.png") no-repeat, url("../../img/floral_flip.png") no-repeat;
    background-repeat: no-repeat;
    background-position: left 110px top 85%, right 110px top 85%;
    transform: translateZ(0);

    .on-handhelds({
        background-image: none;
        padding: 0.5rem 3rem;
        height: @page-header-size-mobile;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid @color-accent;
        border-top: none;
        position: fixed;
        top: 0; left: 0;
        width: 100%;
        background-color: white;
        z-index: 1000;
        .material-shadow(1);
    });
}

.page-footer {
    padding: .5rem 1rem;
    background-color: @color-brand;
    text-align: center;
    font-size: 0.8rem;

    &, a {
        color: white;
    }
}

.page-content {
    padding: 3rem 2rem 2rem;
}

.page-actions {
    margin-top: 5rem;
    padding: 2rem 0rem;
    border-top: 2px solid @color-ui-grey-normal;
}
