.tac {
    padding: .625rem 1rem;
    background-color: #f2f2f2;
    border-radius: 2px;
    margin-bottom: 1rem;
    user-select: none;
    position: relative;

    .form-group {
        border: none;
        padding: 0;
        margin-bottom: .125rem;
        background-color: transparent;
        position: static;
    }

    .help-block {
        margin: 0;
    }

    @media only screen and (min-width: 62em) {
        padding-left: 2.25rem;

        .form-group {
            margin-left: -1.25rem;
        }
    }
}
