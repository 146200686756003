:root, body, textarea, select, input {
    font: 400 14px/1.3 @font-family-default;
    margin: 0;
    padding: 0;
    color: @color-text-default;
}

body {
    background: url("../../img/bg.gif") repeat;
    overflow-y: scroll;

    .on-handhelds({
        padding-top: @page-header-size-mobile;
    });
}

* {
    box-sizing: border-box;
}

a {
    color: @color-accent;
}

h1, h2, h3, h4, h5, h6 {
    font-family: @font-family-accent;
    font-weight: 600;
    margin: 1.25em 0 .5em;

    + * {
        margin-top: 0;
    }

    + & {
        margin-top: 0;
    }

    &[id] a {
        opacity: 0;
        text-decoration: none;
        color: @color-text-light;
        display: inline-block;
        margin-left: .25em;
        pointer-events: none;
        transition: opacity .2s;
    }
}

img {
    max-height: 100%;
    max-width: 100%;
}

dl {
    width: 100%;

    dd {
        padding: 0 1rem;
        margin: 0 0 0 100px;
    }

    dt {
        float: left;
        clear: left;
        width: 100px;
        font-weight: 600;
    }

    &:after {
        content: " ";
        display: block;
        clear: both;
    }
}

hr[title] {
    position: relative;
    border: solid #f2f2f2;
    border-width: 2px 0 0 0;
    margin: 1em 0 2em;
    font-family: @font-family-accent;
    color: @color-accent;
    overflow: visible;

    &:after{
        content: attr(title);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        display: inline-block;
        padding: .3em .6em;
        margin-top: -1px;
    }

    &:not(:first-of-type) {
        margin-top: 3em;
    }
}

ul, ol, dl, p {
    margin-top: 0;
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus,
:-webkit-autofill:active  {
    // disable yellow background in auto-filled input elements
    @-webkit-keyframes autofill {
        to {
            color: @color-text-default;
            background: transparent;
        }
    }

    input&,
    textarea& {
        -webkit-animation-name: autofill;
        -webkit-animation-fill-mode: both;
    }
}
